//
// variables.scss
//

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Livvic:wght@400;500;600;700&display=swap');
// Color
$white: #ffffff;

$primary: #1890ff; //2f55d4
$secondary: #5a6d90;
$success: #2eca8b;
$warning: #f17425;
$info: #17a2b8;
$danger: #e43f52;
$dark: #3c4858;
$black: #161c2d;
$muted: #8492a6;
$light: #f8f9fc;

// Gray
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

// Overlay
$overlay: rgba($dark, 0.7);
$bg-overlay-white: rgba($white, 0.5);

//Body Background
$shadow: 0 0 3px rgba($dark, 0.15);
$shadow-md: 0 5px 13px rgba($dark, 0.2);
$shadow-lg: 0 10px 25px rgba($dark, 0.15);
$footer: lighten($black, 6%);

// Base font
$font-size-base: 16px;

$font-family-base: 'Nunito', sans-serif;
$font-family-secondary: 'Nunito', sans-serif;

//Color Picker/Switcher
$default: #1890ff;
$green: #6dc77a;
$red: #ff5b69;
$purple: #7952b3;
$skyblue: #32c2ec;
$skobleoff: #0f7173;
$cyan: #00c9a7;
$slateblue: #766df4;
$yellow: #f7961c;
$btn-close-bg-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$black}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-width: 1em;

$colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'warning': $warning,
  'info': $info,
  'danger': $danger,
  'dark': $dark,
  'muted': $muted,
  'light': $light,
  'blue': $primary,
  'footer': $footer,
);

$heading-font-sizes: (
  'h1': 42px,
  'h2': 36px,
  'h3': 30px,
  'h4': 24px,
  'h5': 20px,
  'h6': 16px,
);

$display-font-sizes: (
  'display-1': 80px,
  'display-2': 72px,
  'display-3': 64px,
  'display-4': 56px,
);
