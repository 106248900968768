@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/custom.scss';

// //Import Light mode
@import './assets/scss/style.scss';

//Import Dark RTL mode
// @import './assets/scss/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/style-dark.scss';

.img-top {
  max-width: 900px;
  border-radius: 20px;
  -webkit-box-shadow: 7px 13px 24px -25px rgba(66, 68, 90, 1);
  -moz-box-shadow: 7px 13px 24px -25px rgba(66, 68, 90, 1);
  box-shadow: 7px 13px 24px -25px rgba(66, 68, 90, 1);
}

.screenshot {
  border-radius: 20px;
  -webkit-box-shadow: 7px 13px 24px -25px rgba(66, 68, 90, 1);
  -moz-box-shadow: 7px 13px 24px -25px rgba(66, 68, 90, 1);
  box-shadow: 7px 13px 24px -25px rgba(66, 68, 90, 1);
}

.img-modal {
  max-width: 100%;
}

.text-justify {
  text-align: justify;
}

.pad-20 {
  padding: 0 20px;
}
